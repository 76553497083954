import type { UserRoles } from '~/types/Roles'

/** Checks if the currently logged in user has one of the provided roles */
export const userHasRole = (allowedRoles: UserRoles[]) =>
  computed(() => {
    const authStore = useAuthStore()
    const { userDoc } = storeToRefs(authStore)
    const currentUserRole = userDoc.value?.role
    if (!currentUserRole) return false
    return allowedRoles.includes(currentUserRole)
  })
